import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import ReactMarkdown from 'react-markdown';

import styles from './MeasureBreakdown.module.css';

import measurePropType from '../shared/measurePropType';

import AgeRange from './AgeRange';

// const TYPES = {
//   1: 'Direct Assessment',
//   2: 'Survey',
//   3: 'Observation',
//   4: 'Screening Tool',
//   5: 'Interview'
// };

// const SUBJECTS = {
//   1: 'Child',
//   2: 'Caregiver',
//   3: 'Teacher',
//   4: 'Family'
// };

// const RESPONDENTS = {
//   1: 'Child self-report',
//   2: 'Caregiver report on child',
//   3: 'Caregiver self-report',
//   4: 'Caregiver report on family',
//   5: 'Teacher report on child',
//   6: 'Teacher self-report',
//   7: 'Clinician/Researcher report on child',
//   8: 'Clinician/Researcher report on family',
//   9: 'Clinician/Researcher report on caregiver',
//   10: 'Clinician/Researcher report on classroom/childcare setting'
// };

// const ADMINISTRATORS = {
//   1: 'Clinician/Researcher',
//   2: 'Caregiver',
//   3: 'Teacher',
//   'N/A': 'N/A'
// };

const MONETARY_COST = {
  1: 'Free & accessible',
  2: 'Free & not accessible',
  3: 'At cost'
};

function MeasureBreakdown(props) {
  const { measure, compare } = props;

  let timeToComplete;

  if (measure.time_to_complete.gte === measure.time_to_complete.lte) {
    timeToComplete = `${measure.time_to_complete.gte} minutes`;
  } else {
    timeToComplete = `${measure.time_to_complete.gte} - ${measure.time_to_complete.lte} minutes`;
  }

  const Heading = `h${compare ? 3 : 2}`;

  let onClickAccessMeasure = (evt) => {
    let url = evt.target.getAttribute('href');
    if (url && url.length && window.gtag) {
      evt.preventDefault();
      console.log('gtag:access_measure', url);
      window.gtag('event', 'click_access_measure', {
        event_category: 'access_measure',
        event_label: measure.name,
        event_callback: () => {
          document.location = url;
        }
      });
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col mt-2">
          {compare && (
            <div className="row">
              <div className="col">
                <a
                  href={measure.access_measure}
                  className={cn(
                    'btn btn-primary btn-purple d-inline-block mb-4',
                    styles.publisherButton
                  )}
                  onClick={onClickAccessMeasure}
                >
                  Access Measure
                </a>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-lg mb-4 mr-4">
          <Heading className={styles.heading}>Measure Type</Heading>
          <div>{measure.measure_type && measure.measure_type.join(', ')}</div>
        </div>

        <div className="col-lg mb-4 mr-4">
          <Heading className={styles.heading}>Topic</Heading>
          <div>{measure.topic.join(', ')}</div>
        </div>

        <div className="col-lg mb-4 mr-4">
          <Heading className={styles.heading}>Publication Year</Heading>
          <div>{measure.year_of_publication}</div>
        </div>
      </div>

      <AgeRange range={measure.child_age} adult={measure.adult === 1} />

      <div className="row">
        <div className="col-lg mb-4 mr-4">
          <Heading className={styles.heading}>Subject</Heading>
          <div>{measure.subject && measure.subject.join(', ')}</div>
        </div>

        <div className="col-lg mb-4 mr-4">
          <Heading className={styles.heading}>Respondent</Heading>
          <div>{measure.respondent && measure.respondent.join(', ')}</div>
        </div>

        <div className="col-lg mb-4 mr-4">
          <Heading className={styles.heading}>Administrator</Heading>
          <div>{measure.administrator && measure.administrator.join(', ')}</div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg mb-4 mr-4">
          <Heading className={styles.heading}>Electronic Version</Heading>
          <div>{measure.electronic ? 'Yes' : 'No'}</div>
        </div>

        <div className="col-lg mb-4 mr-4">
          <Heading className={styles.heading}>Time to Complete</Heading>
          <div>{timeToComplete}</div>
        </div>

        <div className="col-lg mb-4 mr-4">
          <Heading className={styles.heading}>Cost</Heading>
          <div>{lookup(MONETARY_COST, measure.monetary_cost)}</div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg mb-4">
          <Heading className={styles.heading}>Languages</Heading>
          <div>{measure.languages.join(', ')}</div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg mb-2">
          <Heading className={styles.heading}>Did You Know?</Heading>
          <div>
            <ReactMarkdown source={measure.did_you_know} />
          </div>
        </div>
      </div>

      {measure.references && (
        <div className="row">
          <div className="col-lg mb-4">
            <Heading className={styles.heading}>References</Heading>

            {measure.references.split(';').map((reference) => {
              return <ReactMarkdown key={reference} source={reference} />;
            })}
          </div>
        </div>
      )}

      {!compare && measure.program_link && (
        <div className="row">
          <div className="col-lg mb-4">
            <a
              href={measure.program_link}
              className={cn('btn btn-primary btn-purple my-4', styles.programsButton)}
              target="_blank"
              rel="noopener noreferrer"
            >
              View Programs Using this Measure
            </a>
          </div>
        </div>
      )}
    </div>
  );
}

function lookup(map, field) {
  if (Array.isArray(field)) {
    return field
      .map((f) => {
        return map[f];
      })
      .join(', ');
  }

  return map[field];
}

// function measureType(measure) {
//   return measure.measure_type
//     .map((type) => {
//       return TYPES[type];
//     })
//     .join(', ');
// }

MeasureBreakdown.propTypes = {
  measure: measurePropType.isRequired,
  compare: PropTypes.bool
};

MeasureBreakdown.defaultProps = {
  compare: false
};

export default MeasureBreakdown;
