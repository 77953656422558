import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { fabric } from 'fabric';
import { snakeCase } from 'lodash';
import FontFaceObserver from 'fontfaceobserver';

import measurePropType from '../shared/measurePropType';

const BLACK = '#232323';
const YELLOW = '#ebaf3d';
const PURPLE = '#7e3d88';

const TOP = 'cost';
const RIGHT = 'usability';
const BOTTOM = 'culturalSensitivity';
const LEFT = 'technicalMerit';

const ABBRV = {
  usability: { sm: 'U', lg: 'USABILITY' },
  cost: { sm: 'C', lg: 'COST' },
  technicalMerit: { sm: 'TM', lg: 'TECH. MERIT' },
  culturalSensitivity: { sm: 'CR', lg: 'CR' }
};

function Diamond(props) {
  const { canvasId, measure, criteria, size } = props;

  const criteriaKey = criteria ? Object.values(criteria).join(':') : null;

  useEffect(() => {
    const CANVAS_SIZE = size === 'large' ? 240 : 120;
    const canvas = new fabric.Canvas(canvasId, {
      hoverCursor: 'auto',
      selection: false,
      width: CANVAS_SIZE,
      height: CANVAS_SIZE
    });

    renderDiamond(canvas, measure, criteria, size);
    renderLabels(canvas, size);

    return () => {
      canvas.dispose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvasId, measure?.uuid, criteriaKey, size]);

  const descriptionId = measure
    ? `diamond-description-${measure.uuid}`
    : `diamond-description-criteria`;

  const descriptionContent = diamondDescription(measure, criteria);

  return (
    <div>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
      <div tabIndex="0" aria-labelledby={descriptionId}>
        <canvas id={canvasId} />
      </div>

      <div id={descriptionId} className="d-none">
        {descriptionContent}
      </div>
    </div>
  );
}

function renderDiamond(canvas, measure, criteria, size) {
  new FontFaceObserver('Lexend Bold').load().then(() => {
    const CANVAS_SIZE = size === 'large' ? 240 : 100;
    const MARGIN = size === 'large' ? 50 : 10;
    const DIAMOND_SIZE = CANVAS_SIZE - 2 * MARGIN;
    const MIDDLE = DIAMOND_SIZE / 2;

    const group = new fabric.Group([], {
      selectable: false
    });

    group.addWithUpdate(
      new fabric.Polygon(
        [
          { x: MIDDLE, y: 0 },
          { x: DIAMOND_SIZE, y: MIDDLE },
          { x: MIDDLE, y: DIAMOND_SIZE },
          { x: 0, y: MIDDLE }
        ],
        {
          stroke: BLACK,
          fill: 'white'
        }
      )
    );

    if (measure) {
      group.addWithUpdate(
        new fabric.Polygon(
          [
            { x: MIDDLE, y: MIDDLE - (MIDDLE * measure[snakeCase(TOP)]) / 10 },
            { x: MIDDLE + (MIDDLE * measure[snakeCase(RIGHT)]) / 10, y: MIDDLE },
            { x: MIDDLE, y: MIDDLE + (MIDDLE * measure[snakeCase(BOTTOM)]) / 10 },
            { x: MIDDLE - (MIDDLE * measure[snakeCase(LEFT)]) / 10, y: MIDDLE }
          ],
          {
            stroke: null,
            fill: YELLOW
          }
        )
      );
    }

    const criteriaPolyline = new fabric.Polyline(
      [
        { x: MIDDLE, y: MIDDLE - (MIDDLE * criteria[TOP]) / 10 },
        { x: MIDDLE + (MIDDLE * criteria[RIGHT]) / 10, y: MIDDLE },
        { x: MIDDLE, y: MIDDLE + (MIDDLE * criteria[BOTTOM]) / 10 },
        { x: MIDDLE - (MIDDLE * criteria[LEFT]) / 10, y: MIDDLE },
        { x: MIDDLE, y: MIDDLE - (MIDDLE * criteria[TOP]) / 10 }
      ],
      {
        stroke: PURPLE,
        strokeDashArray: [4, 4],
        fill: null
      }
    );

    group.addWithUpdate(criteriaPolyline);

    group.addWithUpdate(new fabric.Line([MIDDLE, 0, MIDDLE, DIAMOND_SIZE], { stroke: BLACK }));
    group.addWithUpdate(new fabric.Line([0, MIDDLE, DIAMOND_SIZE, MIDDLE], { stroke: BLACK }));

    canvas.add(group);

    group.set('left', MARGIN + (size === 'small' ? 16 : -6));
    group.set('top', MARGIN + (size === 'small' ? 10 : -10));
  });
}

function renderLabels(canvas, size) {
  const labelProps = {
    fontFamily: 'Lexend Bold',
    fontSize: size === 'small' ? 14 : 16,
    fill: BLACK,
    selectable: false
  };

  if (size === 'small') {
    canvas.add(new fabric.Text(ABBRV[LEFT].sm, { left: 2, top: 53, ...labelProps }));
    canvas.add(new fabric.Text(ABBRV[RIGHT].sm, { left: 110, top: 53, ...labelProps }));
    canvas.add(new fabric.Text(ABBRV[TOP].sm, { left: 61, top: 2, ...labelProps }));
    canvas.add(new fabric.Text(ABBRV[BOTTOM].sm, { left: 55, top: 105, ...labelProps }));
  } else if (size === 'large') {
    canvas.add(new fabric.Text(ABBRV[LEFT].sm, { left: 12, top: 103, ...labelProps }));
    canvas.add(new fabric.Text(ABBRV[RIGHT].sm, { left: 190, top: 101, ...labelProps }));
    canvas.add(new fabric.Text(ABBRV[TOP].sm, { left: 108, top: 15, ...labelProps })); // left:70. top: 10
    canvas.add(new fabric.Text(ABBRV[BOTTOM].sm, { left: 105, top: 190, ...labelProps })); // left: 63, top: 195
  }
}

function diamondDescription(measure, criteria) {
  if (!criteria) return '';

  if (!measure) {
    return `Your preferred usability score is ${criteria.usability} out of 10 points. Your preferred
      cost score is ${criteria.cost} out of 10 points. Your preferred technical merit score is
      ${criteria.technicalMerit} out of 10 points. Your preferred cultural relevance score is
      ${criteria.culturalSensitivity} out of 10 points.`;
  }

  let description = `This measure has a usability score of ${measure.usability} out of 10 points.
   Your preferred usability score is ${criteria.usability} out of 10 points. This measure has a
   cost score of ${measure.cost} out of 10 points. Your preferred cost score is ${criteria.cost}
   out of 10 points. This measure has a technical merit score of ${measure.technical_merit} out of
   10 points. Your preferred technical merit score is ${criteria.technicalMerit} out of 10 points.
   This measure has a cultural relevance score of ${measure.cultural_sensitivity} out of 10
   points. Your preferred cultural relevance score is ${criteria.culturalSensitivity} out of 10
   points.`;

  if (measure.meets_minimum_cutoff === 'N') {
    description += 'This measure does not meet the minimum score for technical merit';
  } else if (measure.meetr_minimum_cutoff === 'NI') {
    description += 'There is not enough information about the technical merit for this measure';
  }

  return description;
}

Diamond.propTypes = {
  canvasId: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  measure: measurePropType,
  criteria: PropTypes.shape({
    usability: PropTypes.number,
    cost: PropTypes.number,
    technicalMerit: PropTypes.number,
    culturalSensitivity: PropTypes.number
  }).isRequired
};

Diamond.defaultProps = {
  measure: null
};

export default Diamond;
