import React, { useState } from 'react';
import cn from 'classnames';

import styles from './Criteria.module.css';

import Diamond from './Diamond';
import { getCriteria, storageSet } from '../shared/helpers';

function Criteria() {
  const [criteria, setCriteria] = useState(getCriteria());

  const onClick = () => {
    storageSet('criteria', JSON.stringify(criteria));

    const current = window.location;
    const params = new URLSearchParams(current.search);
    params.delete('openPreferences');

    let updated = `//${current.host}${current.pathname}`;

    if (params.toString().length > 0) {
      updated += `?${params.toString()}`;
    }

    window.location = updated;
  };

  return (
    <span>
      <span
        data-toggle="popover"
        data-placement="top"
        data-content="Adjust your search by setting a preferred score for each category."
        className="pt-3"
      >
        <a
          href="#criteriaModal"
          data-toggle="modal"
          data-target="#criteriaModal"
          className={cn('mr-3 pt-1 pl-1 align-top d-inline-block', styles.preferencesLink)}
        >
          <div className={cn('mr-2 d-inline-block', styles.preferencesIcon)} />
          <div className={cn('d-inline-block', styles.editWord)}>Edit</div>
        </a>
      </span>

      <div className="modal" tabIndex="-1" role="dialog" id="criteriaModal">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header mx-3">
              <h1 className="modal-title">Measure Matching Preferences</h1>

              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body mx-3">
              <p>
                All measures are scored on four key categories. Set a preferred score for each
                category below. Your search results will display each measure&apos;s score in
                comparison to your preferences.
              </p>

              <div className="row">
                <div className="col-lg mt-2">
                  {criterium(criteria, setCriteria, 'cost', 'Cost')}
                  {criterium(criteria, setCriteria, 'usability', 'Usability')}
                  {criterium(criteria, setCriteria, 'culturalSensitivity', 'Cultural Relevance')}
                  {criterium(criteria, setCriteria, 'technicalMerit', 'Technical Merit')}
                </div>
                <div className={cn('col-lg', styles.diamondCol)}>
                  <Diamond canvasId="criteriaDiamond" size="large" criteria={criteria} />
                </div>
              </div>
            </div>

            <div className="modal-footer mx-3">
              <button type="button" className={cn('btn my-2', styles.save)} onClick={onClick}>
                Set Your Preferences
              </button>
            </div>
          </div>
        </div>
      </div>
    </span>
  );
}

function criterium(criteria, setCriteria, field, title) {
  const onBlur = (evt) => {
    const { value } = evt.target;

    if (!value) {
      evt.target.value = criteria[field];
    }
  };

  const onChange = (evt) => {
    const { value } = evt.target;

    if (!value) {
      return;
    }

    if (value < 0) {
      evt.target.value = 0;
    } else if (value > 10) {
      evt.target.value = 10;
    }

    const newCriteria = { ...criteria };
    newCriteria[field] = parseFloat(evt.target.value);

    setCriteria(newCriteria);
  };

  return (
    <div className="row">
      <div className="col">
        <input
          type="number"
          min="0"
          max="10"
          step="1"
          defaultValue={criteria[field]}
          onBlur={onBlur}
          onChange={onChange}
          className={cn('form-control mr-2', styles.numInput)}
          data-field={field}
          id={`${field}-input`}
        />

        <label htmlFor={`${field}-input`}>
          <span className={cn('mr-2', styles.outOfTen)}>/ 10</span> {title}
        </label>
      </div>
    </div>
  );
}

export default Criteria;
