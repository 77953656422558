import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as fasHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons';

import styles from './Favorite.module.css';

import measurePropType from '../shared/measurePropType';
import { storageGet, storageSet } from '../shared/helpers';

function MeasureOverview(props) {
  const { measure } = props;

  const [, setFavorites] = useState(storageGet('favorites'));

  return (
    <button
      type="button"
      className={styles.Favorite}
      onClick={toggleFavorite.bind(null, setFavorites, measure)}
    >
      {isFavorite(measure) && <FontAwesomeIcon icon={fasHeart} />}
      {!isFavorite(measure) && <FontAwesomeIcon icon={farHeart} />}
    </button>
  );
}

function toggleFavorite(setFavorites, measure) {
  const favorites = storageGet('favorites');
  let newFavorites;

  if (favorites.includes(measure.uuid)) {
    newFavorites = favorites.filter((f) => {
      return f !== measure.uuid;
    });
  } else {
    newFavorites = favorites.concat(measure.uuid);
  }

  setFavorites(newFavorites);
  storageSet('favorites', JSON.stringify(newFavorites));
}

function isFavorite(measure) {
  const favorites = localStorage.getItem('favorites');
  return favorites.includes(measure.uuid);
}

MeasureOverview.propTypes = {
  measure: measurePropType.isRequired
};

export default MeasureOverview;
