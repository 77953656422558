import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './AgeRange.module.css';

const MIN_WIDTH = 160;

function AgeRange(props) {
  const { range, adult } = props;
  const rangeRef = useRef(null);
  const eightOrLess = range.lte <= 8;
  const max_years = eightOrLess ? 8 : 18;

  useEffect(() => {
    if (rangeRef.current && rangeRef.current.offsetWidth < MIN_WIDTH) {
      const el = rangeRef.current.querySelector('.col');
      el.innerText = el.innerText
        .replace('month(s)', 'm')
        .replace('months', 'm')
        .replace('years', 'y');
    }
  }, []);

  const rangeMargins = {
    marginLeft: `${(range.gte / max_years) * 100}%`,
    marginRight: `${100 - (Math.min(range.lte, max_years) / max_years) * 100}%`
  };

  const rangeLabel = (range, endpoint) => {
    if (range.lte - range.gte < 1 && range.lte <= 3) {
      if (endpoint === 'lte') return '';

      return `${(range.gte * 12).toFixed(0)} - ${(range.lte * 12).toFixed(0)} months`;
    }

    const years = range[endpoint];
    const eightPlus = years === 2 ** 31 - 1;
    const capped = eightPlus ? max_years : years;

    return capped < 3
      ? `${(capped * 12).toFixed(0)} month(s)`
      : `${capped % 1 === 0 ? capped : capped.toFixed(1)}${eightPlus ? '+' : ''} years`;
  };

  const visual = (
    <div
      aria-label={`This is a timeline depicting an age range between ${rangeLabel(
        range,
        'gte'
      )} and ${rangeLabel(range, 'lte')}`}
    >
      <div ref={rangeRef} className={cn('row', styles.range)} style={rangeMargins}>
        <div className="col p-0 nowrap">{rangeLabel(range, 'gte')}</div>
        <div className="col p-0 nowrap text-right">{rangeLabel(range, 'lte')}</div>
      </div>

      <div className={cn('w-100', styles.numberLine)} />

      <div className="row m-0">
        {[...Array(3).keys()].map((i) => (
          <div key={i} className={cn('col p-0', styles.tick)}>
            <div className="row m-0 flex-nowrap">
              <div className={cn('col p-0')} />

              {eightOrLess &&
                [...Array(11).keys()].map((j) => (
                  <div key={j} className={cn('col p-0', styles.subtick)} />
                ))}
            </div>
          </div>
        ))}

        {[...Array(max_years - 4).keys()].map((i) => (
          <div key={i} className={cn('col p-0', styles.tick)} />
        ))}

        <div className={cn('col p-0', styles.tick, styles.last)} />
      </div>

      <div className="row">
        <div className="col">
          <div>0</div>
        </div>

        <div className="col">
          <div className="float-right">{eightOrLess ? '8' : '18+'}</div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={cn('mb-4', styles.AgeRange)}>
      <div className="row">
        <div className="col">
          <h2 className={cn('mb-2', styles.heading)}>Age Range</h2>
          {adult ? 'Adult' : visual}
        </div>
      </div>
    </div>
  );
}

AgeRange.propTypes = {
  range: PropTypes.shape({
    gte: PropTypes.number,
    lte: PropTypes.number
  }).isRequired,
  adult: PropTypes.bool.isRequired
};

export default AgeRange;
