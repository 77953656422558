import { computed, action, createStore } from 'easy-peasy';
import { allDefaults } from './helpers';

const store = createStore({
  searchState: {},
  setSearchState: action((state, newState) => {
    state.searchState = newState;
  }),
  updateSearchState: action((state, payload) => {
    state.searchState = { ...state.searchState, ...payload };
  }),

  resetState: action((state, _payload) => {
    state.searchState = {};
  }),

  searchQuery: '',
  setSearchQuery: action((state, query) => {
    state.searchQuery = query;
  }),

  measuresToCompare: [],
  setMeasuresToCompare: action((state, payload) => {
    state.measuresToCompare = payload;
    // storageSet('measuresToCompare', JSON.stringify(payload));
  }),

  adultOnly: computed((state) => {
    const adult = state.searchState.adult;
    return !!(adult && Array.isArray(adult.value) && adult.value[0] === '1');
  }),

  clearAllDisabled: computed((state) => allDefaults(state.searchState))
});

export default store;
