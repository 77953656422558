import React from 'react';
import { Link } from 'react-router-dom';
import { ResultList, ReactiveList } from '@appbaseio/reactivesearch';
import cn from 'classnames';
import { useStoreActions, useStoreState } from 'easy-peasy';

import styles from './MeasureList.module.css';

import FamilyLink from './FamilyLink';
import Diamond from './Diamond';
import Criteria from './Criteria';
import { getCriteria } from '../shared/helpers';

function MeasureList() {
  const { adultOnly, measuresToCompare } = useStoreState((s) => s);
  const setMeasuresToCompare = useStoreActions((s) => s.setMeasuresToCompare);

  const onChangeCompare = (evt) => {
    const uuid = evt.target.id.match(/compare-(.*)/)[1];

    let newMeasuresToCompare;

    if (!measuresToCompare.includes(uuid)) {
      newMeasuresToCompare = measuresToCompare.concat(uuid);
    } else {
      newMeasuresToCompare = measuresToCompare.filter((item) => {
        return item !== uuid;
      });
    }

    setMeasuresToCompare(newMeasuresToCompare);
  };

  const clearMeasuresToCompare = () => {
    setMeasuresToCompare([]);
  };

  const filters = [
    'searchBox',
    'measureType',
    'topic',
    'languages',
    'timeToComplete',
    'administrator',
    'respondent',
    'monetaryCost',
    'adult'
  ];

  const criteria = getCriteria();

  const sortingCriteria = {
    usability: criteria?.usability || 5,
    cost: criteria?.cost || 5,
    technical_merit: criteria?.technicalMerit || 5,
    cultural_sensitivity: criteria?.culturalSensitivity || 5
  };

  // We use this for sorting by similarity based on the sortingCriteria,
  // unfortunatelly this is pretty inneficient and will slow down heavily all queries
  const defaultQuery = () => {
    const total = Object.values(sortingCriteria).reduce((t, v) => t + v, 0);
    const weightedSimilarity = ([field, crit]) => {
      //  ( Ci/sum(Cn) ) * ( 1 - abs(Vi-Ci)/Vmax )
      const weight = crit / total;
      const distance = `Math.abs(doc['${field}'].value - ${crit})`;
      return `${weight} * (1.0 - ${distance}/10.0)`;
    };
    return {
      sort: {
        _script: {
          type: 'number',
          script: {
            lang: 'painless',
            source: Object.entries(sortingCriteria).map(weightedSimilarity).join(' + ')
          },
          order: 'desc'
        }
      }
    };
  };

  return (
    <div className={cn('col-lg', styles.MeasureList)}>
      <ReactiveList
        componentId="measureList"
        dataField="original_title"
        size={15}
        defaultQuery={defaultQuery}
        pagination
        scrollOnChange={false}
        URLParams
        react={{
          and: adultOnly ? filters : filters.concat('ageRange')
        }}
        render={({ data }) => (
          <ReactiveList.ResultListWrapper>
            <h2 className="d-none">Search Results</h2>

            <div className="row">
              <div className="col">
                <div className={cn('mt-1', styles.controls)}>
                  <Criteria />

                  <Link
                    to={`/measures/compare?uuids=${measuresToCompare.join(',')}`}
                    className={cn(
                      'btn btn-primary btn-purple mb-4 mt-1 mr-1',
                      styles.compareButton,
                      {
                        disabled: measuresToCompare.length < 2
                      }
                    )}
                  >
                    Compare
                  </Link>

                  <button
                    type="button"
                    className={cn('ml-1 mr-2', styles.clearButton, {
                      disabled: measuresToCompare.length > 0
                    })}
                    onClick={clearMeasuresToCompare}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>

            {data.map((measure) => {
              const disabled =
                measuresToCompare.length >= 3 && !measuresToCompare.includes(measure.uuid);
              return (
                <div key={measure.uuid} className={cn('pb-2 mb-2 ml-1', styles.measure)}>
                  <div className="row w-100">
                    <div className="col-sm pr-4">
                      <ResultList.Content>
                        <FamilyLink family={measure.measure_family_name} small />

                        <h3 className={styles.measureTitle}>
                          <Link to={`/measure/${measure.uuid}`} className={styles.link}>
                            {measure.name}
                          </Link>
                        </h3>

                        <div>{measure.short_measure_description}</div>
                      </ResultList.Content>
                    </div>

                    <div className={cn('col-sm-2 p-0 pt-1', styles.diamondCol)}>
                      <Diamond
                        canvasId={`diamond-${measure.uuid}`}
                        measure={measure}
                        criteria={criteria}
                        size="small"
                      />
                    </div>

                    <div className={cn('col-sm-2 mt-4 pr-0 text-center', styles.compareCol)}>
                      <form className={cn('d-inline-block', styles.compareForm)}>
                        <fieldset>
                          <input
                            type="checkbox"
                            className={styles.checkbox}
                            id={`compare-${measure.uuid}`}
                            onChange={onChangeCompare}
                            disabled={disabled}
                            checked={measuresToCompare.includes(measure.uuid)}
                          />

                          <label
                            htmlFor={`compare-${measure.uuid}`}
                            className={styles.compareLabel}
                            aria-label={`Select this measure to compare it with others
                                        (up to 3 can be selected)`}
                          >
                            <div
                              className={cn('', {
                                [styles.disabled]: disabled
                              })}
                            >
                              Compare
                            </div>
                          </label>
                        </fieldset>
                      </form>
                    </div>
                  </div>
                </div>
              );
            })}
          </ReactiveList.ResultListWrapper>
        )}
        renderPagination={({ totalPages, currentPage, setPage }) => {
          // OBS: currentPage is zero based (it's an index)

          if (!Number.isFinite(totalPages)) {
            return null;
          }

          const onClick = (val) => (_e) => {
            setPage(parseInt(val, 10));
          };

          return (
            <ol className={cn('pt-3', styles.pagination)}>
              {currentPage > 0 &&
                paginationItem(
                  '< Prev',
                  currentPage - 1,
                  { wide: true },
                  onClick(currentPage - 1)
                )}

              {[...Array(totalPages).keys()].map((i) =>
                paginationItem((i + 1).toString(), i, { active: i === currentPage }, onClick(i))
              )}

              {currentPage + 1 < totalPages &&
                paginationItem(
                  'Next >',
                  currentPage + 1,
                  { wide: true },
                  onClick(currentPage + 1)
                )}
            </ol>
          );
        }}
        renderResultStats={(stats) => {
          return (
            <div className={styles.numResults} aria-live="polite" aria-atomic="true">
              {stats.numberOfResults} result(s) found
            </div>
          );
        }}
        renderNoResults={() => {
          return (
            <div aria-live="polite" aria-atomic="true">
              No results found
            </div>
          );
        }}
      />
    </div>
  );
}

function paginationItem(text, page, options, onClick) {
  const { active, wide } = options || {};

  return (
    <li
      className={styles.paginationItem}
      /* eslint-disable-next-line react/no-array-index-key */
      key={`measureList-${page}`}
      value={page}
      onClick={onClick}
    >
      <a
        className={cn(styles.paginationLink, { [styles.active]: active }, { [styles.wide]: wide })}
        tabIndex="0"
        href={`?measureList=${page}`}
        alt={`Page ${page}`}
        onClick={(e) => e.preventDefault()}
      >
        {text}
      </a>
    </li>
  );
}

export default MeasureList;
