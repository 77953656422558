import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './MeasureScores.module.css';

import measurePropType from '../shared/measurePropType';
import { getCriteria } from '../shared/helpers';

import Diamond from './Diamond';

function MeasureScores(props) {
  const { measure, compare } = props;

  const mb = compare ? 'mb-2' : 'mb-3';

  const criteria = getCriteria();

  return (
    <div className={cn('row pt-3 mb-4 mx-1', styles.MeasureScores)}>
      <div
        className={cn(
          { 'col-3': !compare },
          { [styles.notCompare]: !compare },
          { [styles.compare]: compare },
          { 'w-100 mx-4': compare }
        )}
      >
        <Diamond
          canvasId={`diamond-${measure.uuid}`}
          measure={measure}
          criteria={criteria}
          size={compare ? 'small' : 'large'}
        />
      </div>

      <div className={cn('pb-2', { col: !compare }, { 'w-100 mx-4 mt-3': compare })}>
        <ul className={cn('px-2 py-2', styles.scores)}>
          <li className={mb}>{score(measure.cost, 'Cost')}</li>
          <li className={mb}>{score(measure.usability, 'Usability')}</li>
          <li className={mb}>{score(measure.cultural_sensitivity, 'Cultural Relevance')}</li>
          <li className={mb}>
            {score(measure.technical_merit, 'Technical Merit', measure.meets_minimum_cutoff)}
          </li>
        </ul>
      </div>
    </div>
  );
}

function score(field, title, meetsMinimumCutoff = 'Y') {
  let cutoffMessage;

  if (meetsMinimumCutoff === 'N') {
    cutoffMessage = 'Score is below IMPACT minimum';
  } else if (meetsMinimumCutoff === 'NI') {
    cutoffMessage = 'Not enough information';
  }

  return (
    <div>
      <div className="d-inline-block">{title}</div>

      <div className="float-right">
        <span className={cn({ [styles.insufficient]: meetsMinimumCutoff !== 'Y' })}>{field}</span>{' '}
        / 10
      </div>

      {meetsMinimumCutoff !== 'Y' && (
        <div className={cn(styles.insufficient, styles.insufficientMessage)}>{cutoffMessage}</div>
      )}
    </div>
  );
}

MeasureScores.propTypes = {
  measure: measurePropType.isRequired,
  compare: PropTypes.bool
};

MeasureScores.defaultProps = {
  compare: false
};

export default MeasureScores;
