import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import collapseStyles from '../shared/collapse.module.css';

const chevrons = (
  <span className="float-right">
    <svg width="16" height="9" className={collapseStyles.chevronDown}>
      <polyline points="0,1 8,8 16,1" fill="transparent" strokeWidth="3" />
    </svg>
    <svg width="16" height="9" className={collapseStyles.chevronUp}>
      <polyline points="0,8 8,1 16,8" fill="transparent" strokeWidth="3" />
    </svg>
  </span>
);

function SearchFilter(props) {
  const { id, title, children, collapsed, popover } = props;

  return (
    <div>
      <h3 id={`${id}Heading`} data-toggle="popover" data-placement="top" data-content={popover}>
        <a
          href={`#${id}Collapse`}
          className={cn({ collapsed }, collapseStyles.trigger)}
          data-toggle="collapse"
          aria-expanded="true"
          aria-controls={`${id}Collapse`}
        >
          {title}
          {chevrons}
        </a>
      </h3>

      <div
        id={`${id}Collapse`}
        className={cn('collapse', { show: !collapsed })}
        aria-labelledby={`${id}Heading`}
      >
        {children}
      </div>
    </div>
  );
}

SearchFilter.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  collapsed: PropTypes.bool,
  popover: PropTypes.string
};

SearchFilter.defaultProps = {
  collapsed: false,
  popover: null
};

export default SearchFilter;
