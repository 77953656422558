import React, { useState } from 'react';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusSquare, faPlusSquare } from '@fortawesome/free-regular-svg-icons';

import styles from './WelcomeBox.module.css';

import scoringDiamond from '../images/scoring-diamond.png';
import { storageGet, storageSet } from '../shared/helpers';

function WelcomeBox() {
  const [collapsed, setCollapsed] = useState(storageGet('collapsedWelcomeBox'));

  const toggleBox = (e) => {
    e.preventDefault();
    storageSet('collapsedWelcomeBox', !collapsed);
    setCollapsed(!collapsed);
  };

  return (
    <div
      className={cn('alert alert-primary alert-dismissable p-4 mb-4 mt-2', styles.welcomeBox)}
      role="alert"
    >
      <div className={cn('row', styles.titleRow)}>
        <div className="col">
          <h2 className={styles.title}>Welcome to the IMPACT Measures Tool®</h2>
        </div>

        <div className="col-1">
          <button
            type="button"
            className={cn('close', styles.toggle)}
            aria-label="Close"
            onClick={toggleBox}
          >
            <FontAwesomeIcon icon={collapsed ? faPlusSquare : faMinusSquare} />
          </button>
        </div>
      </div>

      {!collapsed && (
        <div className="row mr-sm-3">
          <div className={cn('col-lg-4 px-3 py-5 p-sm-5', styles.firstCol)}>
            <div>
              <div className={styles.yellowBlock} />
              Yellow shading represents measure scores
            </div>

            <div>
              <div className={styles.dashedLine} />
              Dashed lines represent your{' '}
              <a href="#criteriaModal" data-toggle="modal" data-target="#criteriaModal">
                preferred scores
              </a>
            </div>
          </div>

          <div className={cn('col-lg-3 ml-3 ml-sm-5 ml-lg-0', styles.secondCol)}>
            <img src={scoringDiamond} alt="Scoring Diamond" className={styles.scoringDiamond} />
          </div>

          <div className="col-lg px-3 pt-5 pb-2 px-sm-5 pt-sm-5 pb-sm-2">
            <ul className={styles.scoringList}>
              <li>
                <span className={styles.abbreviation}>C</span> - How affordable is this measure?
              </li>

              <li>
                <span className={styles.abbreviation}>U</span> - How easy to use is the measure?
              </li>

              <li>
                <span className={styles.abbreviation}>CR</span> - How does the measure serve
                different groups?
              </li>

              <li>
                <span className={styles.abbreviation}>TM</span> - How consistent and accurate is
                the measure?
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}

export default WelcomeBox;
