import React from 'react';
import { Link } from 'react-router-dom';
import styles from './SearchResultsLink.module.css';

function SearchResultsLink() {
  return (
    <div className="row">
      <div className="col mb-3">
        <span className={styles.leftArrow} aria-label="Back icon">
          &#9664;
        </span>
        <Link to="/measures" className={styles.backLink} aria-label="Back link">
          Search Results
        </Link>
      </div>
    </div>
  );
}

export default SearchResultsLink;
