import React from 'react';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import { Helmet } from 'react-helmet-async';

import styles from './PageShowFamily.module.css';

import SearchResultsLink from './SearchResultsLink';
import RelatedMeasures from './RelatedMeasures';

function PageShowFamily() {
  const { family } = useParams();

  return (
    <>
      <Helmet>
        <title>Measure Family: &quot;{family}&quot; - IMPACT Measures Tool</title>
      </Helmet>
      <div>
        <div className="clearfix">
          <a className="float-right" href="/favorites">My Favorites</a>
        </div>

        <SearchResultsLink />

        <div className="row">
          <div className="col mb-3">
            <h1>Measure Family</h1>
          </div>
        </div>

        <div className="row">
          <div className={cn('col px-5 py-4', styles.family)}>
            <div className="row">
              <div className="col-4">
                <h2>What is a measure family?</h2>
                <p>
                  A measure family is a collection of individual measures which share the same core
                  elements but might differ in length, content, intended age group, or language.
                </p>

                <p>
                  While the individual measures in the measure family can be scored, the measure
                  family as a whole is not scored.
                </p>

                <p>
                  You can search for a specific measure family or refine your search by filtering
                  the list of measure families.
                </p>
              </div>

              <div className="col">
                <h2>{family}</h2>
                <RelatedMeasures family={family} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PageShowFamily;
