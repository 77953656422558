import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './FamilyLink.module.css';

function FamilyLink(props) {
  const { family, small } = props;

  if (!family) {
    return <div>&nbsp;</div>;
  }

  return (
    <Link to={`/family/${family}`} className={cn(styles.FamilyLink, { [styles.small]: small })}>
      Measure Family: {family}
    </Link>
  );
}

FamilyLink.propTypes = {
  family: PropTypes.string,
  small: PropTypes.bool
};

FamilyLink.defaultProps = {
  family: null,
  small: false
};

export default FamilyLink;
