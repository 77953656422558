import React, { useEffect, useRef } from 'react';
import { DataSearch } from '@appbaseio/reactivesearch';
import cn from 'classnames';
import { Helmet } from 'react-helmet-async';

// need this to show / hide the bootstrap modal
import * as $ from 'jquery';

import styles from './PageFindMeasures.module.css';

import { urlParam, removeUrlParam } from '../shared/urlParams';
import MeasureList from './MeasureList';
import SearchFilters from './SearchFilters';
import WelcomeBox from './WelcomeBox';
import { useStoreState, useStoreActions } from 'easy-peasy';

function PageFindMeasures(_props) {
  const { searchQuery } = useStoreState((s) => s);
  const { setSearchQuery } = useStoreActions((s) => s);
  const datasearchRef = useRef(null);

  useEffect(() => {
    const query = urlParam('search');
    if (query && query.length > 0) {
      setSearchQuery(query);
      if (urlParam('search')) {
        sendGaSearchEvent(query);
        removeUrlParam('search');
      }
    }
  }, [setSearchQuery]);

  useEffect(() => {
    if (urlParam('openPreferences')) {
      $('#criteriaModal').modal('show');
      removeUrlParam('openPreferences');
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Find Measures - IMPACT Measures Tool</title>
      </Helmet>

      <div className={styles.PageFindMeasures}>
        <div className="row">
          <div className="col">
            <div className="row">
              <div className="col">
                <div className="clearfix">
                  <a className="float-right" href="/favorites">My Favorites</a>
                </div>

                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="SearchBox" className={cn('mr-2', styles.searchWrapper)}>
                  <h1 className="mb-4">Find Measures</h1>

                  <DataSearch
                    ref={datasearchRef}
                    componentId="searchBox"
                    dataField={[
                      'name',
                      'program_name',
                      'program_link',
                      // 'measure_family_name',
                      'did_you_know',
                      'short_measure_description',
                      'long_measure_description',
                      'reference',
                      'access_measure',
                      'topic_text'
                    ]}
                    debounce={200}
                    fieldWeights={[10, 1, 1, 1, 1, 10, 10, 1, 1, 10]}
                    fuzziness={2}
                    queryFormat="and"
                    placeholder="Add a title, subject, or keyword"
                    autosuggest={false}
                    showClear
                    className={styles.searchBox}
                    value={searchQuery}
                    onKeyDown={(event, triggerQuery) => {
                      if (event.key === 'Enter') {
                        triggerQuery(searchQuery);
                        sendGaSearchEvent(searchQuery);
                      }
                    }}
                    onChange={(value) => {
                      setSearchQuery(value);
                    }}
                  />
                </label>

                <button
                  type="button"
                  className={cn('btn btn-primary btn-purple d-inline-block', styles.searchButton)}
                  onClick={() => {
                    datasearchRef.current.triggerQuery();
                  }}
                >
                  Search
                </button>
              </div>
            </div>

            <WelcomeBox />

            <div className="row mt-4 mr-1">
              <SearchFilters />
              <MeasureList />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function sendGaSearchEvent(query, callback = null) {
  if (!window.gtag) return;

  console.log('sending event to google analytics...');
  window.gtag('event', 'search', {
    search_term: query,
    event_callback: callback
  });
}

PageFindMeasures.propTypes = {};

export default PageFindMeasures;
