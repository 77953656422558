import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import { DiscussionEmbed } from 'disqus-react';
import { Helmet } from 'react-helmet-async';

import styles from './PageShowMeasure.module.css';

import SearchResultsLink from './SearchResultsLink';
import MeasureOverview from './MeasureOverview';
import MeasureBreakdown from './MeasureBreakdown';
import MeasureScores from './MeasureScores';
import RelatedMeasures from './RelatedMeasures';
import { esQuery } from '../shared/helpers';

function PageShowMeasure() {
  const { uuid } = useParams();
  const [measure, setMeasure] = useState(null);

  useEffect(() => {
    esQuery({ query: { term: { uuid } } }).then((res) => {
      const hit = res.hits.hits[0];
      setMeasure(hit ? hit._source : null);
    });
  }, [uuid]);

  if (!measure) return null;

  return (
    <>
      <Helmet>
        <title>Measure: &quot;{measure.name}&quot; - IMPACT Measures Tool</title>
      </Helmet>

      <div className={styles.PageShowMeasure}>
        <SearchResultsLink />

        <div className="row">
          <div className={cn('col px-5 py-4', styles.measure)}>
            <MeasureOverview measure={measure} />

            <div className="row">
              <div className="col">
                <MeasureBreakdown measure={measure} />

                {measure.measure_family_name && (
                  <div className="row">
                    <div className="col">
                      <RelatedMeasures
                        family={measure.measure_family_name}
                        currentUuid={measure.uuid}
                      />
                    </div>
                  </div>
                )}
              </div>

              <div className={cn('col', styles.scoreCol)}>
                <MeasureScores measure={measure} />
              </div>
            </div>
            <DiscussionEmbed
              shortname="ecprism"
              config={{
                url: window.location.href,
                identifier: measure.uuid,
                title: measure.name
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default PageShowMeasure;
