import { Base64 } from 'js-base64';

const URLS = {
  home: '/',
  measures: '/measures',
  about: '/about',
  scoring: '/scoring',
  learn: '/learn',
  favorites: '/favorites',
  terms: '/terms'
};

export const DEFAULT_CRITERIA = {
  usability: 5.0,
  cost: 5.0,
  technicalMerit: 5.0,
  culturalSensitivity: 5.0
};

// const RESPONDENT = [
//   'Child self-report',
//   'Caregiver report on child',
//   'Caregiver self-report',
//   'Caregiver report on family',
//   'Teacher report on child',
//   'Teacher self-report',
//   'Clinician/Researcher report on child',
//   'Clinician/Researcher report on family',
//   'Clinician/Researcher report on caregiver',
//   'Clinician/Researcher report on classroom/childcare setting'
// ];
// const ADMINISTRATOR = ['Clinician / Researcher', 'Caregiver', 'Teacher'];
// const MEASURE_TYPE = ['Direct Assessment', 'Survey', 'Observation', 'Screening Tool', 'Interview'];

export const TIME_TO_COMPLETE_MAX = 120;
export const CHILD_AGE_MAX = 18;

export const esHost = () => {
  const host = process.env.REACT_APP_ES_HOST;
  return window.location.host.includes('localhost') && !host ? 'http://localhost:9200' : host;
};

export const esCredentials = () => {
  const creds = process.env.REACT_APP_ES_CREDENTIALS;
  return window.location.host.includes('localhost') && !creds ? null : creds;
};

export const esQuery = async (query) => {
  const esURL = new URL('/measures/_search', esHost());

  const resp = await fetch(esURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-ndjson',
      Authorization: `Basic ${Base64.encode(esCredentials())}`
    },
    body: JSON.stringify(query)
  });
  const data = await resp.json();
  return data;
};

export const urlFor = (name) => URLS[name] || '/';

export const storageSet = (name, value) => localStorage.setItem(name, value);
export const storageGet = (name) => JSON.parse(localStorage.getItem(name));

export const getCriteria = () => storageGet('criteria') || DEFAULT_CRITERIA;

export const isDefault = (searchState, field) => {
  const value = searchState[field] && searchState[field].value;

  if (!value) {
    return true;
  }

  if (Array.isArray(value) && value.length === 0) {
    return true;
  }

  // the timeToComplete range is at its default values
  if (
    Array.isArray(value) &&
    field === 'timeToComplete' &&
    value[0] === 0 &&
    value[1] === TIME_TO_COMPLETE_MAX
  ) {
    return true;
  }

  // the ageRange range is at its default values
  if (
    Array.isArray(value) &&
    field === 'ageRange' &&
    value[0] === 0 &&
    value[1] === CHILD_AGE_MAX
  ) {
    return true;
  }

  return false;
};

export const allDefaults = (searchState) => {
  if (
    isDefault(searchState, 'measureType') &&
    isDefault(searchState, 'ageRange') &&
    isDefault(searchState, 'topic') &&
    isDefault(searchState, 'languages') &&
    isDefault(searchState, 'timeToComplete') &&
    isDefault(searchState, 'administrator') &&
    isDefault(searchState, 'respondent') &&
    isDefault(searchState, 'monetaryCost') &&
    isDefault(searchState, 'adult')
  ) {
    return true;
  }

  return false;
};

export const dig = (state, field, options = {}) => {
  const value = state[field] && state[field].value;

  if (options.range) {
    return {
      start: value ? value[0] : 0,
      end: value ? Math.min(value[1], options.max) : options.max
    };
  }

  return value;
};
