export function urlParam(name) {
  const {
    location: { search }
  } = window;
  const params = new URLSearchParams(search);

  return params.get(name);
}

export function removeUrlParam(param) {
  const params = new URLSearchParams(window.location.search);
  params.delete(param);
  const questionMark = Array.from(params).length > 0 ? '?' : '';
  window.history.replaceState(null, null, `/measures${questionMark}${params.toString()}`);
}
