import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { ReactiveComponent } from '@appbaseio/reactivesearch';
import { Helmet } from 'react-helmet-async';

import styles from './PageFavorites.module.css';

import Diamond from './Diamond';
import Favorite from './Favorite';
import { getCriteria, storageGet } from '../shared/helpers';

function PageFavorites() {
  const favoriteUuids = storageGet('favorites') || [];

  return (
    <ReactiveComponent
      componentId="fetchMeasure"
      defaultQuery={() => ({
        query: {
          terms: { uuid: favoriteUuids }
        }
      })}
      render={(results) => {
        const measures = results.data;

        return (
          <>
            <Helmet>
              <title>My Favorites - IMPACT Measures Tool</title>
            </Helmet>

            <div>
              <div className="row">
                <div className="col mb-3">
                  <h1>My Favorites</h1>
                </div>
              </div>

              <div className="row">
                <div className={cn('col px-4 py-5', styles.favorites)}>
                  {favoriteUuids.length > 0 && (
                    <ul className={styles.favoriteList}>
                      {measures.map((measure) => {
                        return (
                          <li key={measure.uuid} className={cn('py-2', styles.listItem)}>
                            <div className="row">
                              <div className={cn('col-sm', styles.heartCol)}>
                                <Favorite measure={measure} />
                              </div>

                              <div className="col-sm mt-1">
                                <div className={styles.name}>
                                  <Link to={`/measure/${measure.uuid}`} className={styles.link}>
                                    {measure.name}
                                  </Link>
                                </div>

                                <div className={styles.description}>
                                  {measure.short_measure_description}
                                </div>
                              </div>

                              <div className="col-sm-3">
                                <Diamond
                                  canvasId={`diamond-${measure.uuid}`}
                                  measure={measure}
                                  criteria={getCriteria()}
                                  size="small"
                                />
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  )}

                  {favoriteUuids.length === 0 && (
                    <div>You have not selected any measures as favorites.</div>
                  )}
                </div>
              </div>
            </div>
          </>
        );
      }}
    />
  );
}

export default PageFavorites;
