import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './MeasureOverview.module.css';

import measurePropType from '../shared/measurePropType';

import FamilyLink from './FamilyLink';
import Favorite from './Favorite';

function MeasureOverview(props) {
  const { measure, compare } = props;

  const Heading = `h${compare ? 2 : 1}`;

  let name;

  if (compare) {
    name = (
      <Link to={`/measure/${measure.uuid}`}>
        <Heading className={styles.linkedMeasureName}>{measure.name}</Heading>
      </Link>
    );
  } else {
    name = <Heading>{measure.name}</Heading>;
  }

  let onClickAccessMeasure = (evt) => {
    let url = evt.target.getAttribute('href');
    if (url && url.length && window.gtag) {
      evt.preventDefault();
      console.log('gtag:access_measure', url);
      window.gtag('event', 'click_access_measure', {
        event_category: 'access_measure',
        event_label: measure.name,
        event_callback: () => {
          document.location = url;
        }
      });
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col mb-1">
          <FamilyLink family={measure.measure_family_name} />
        </div>
      </div>

      <div className="row">
        <div className={cn('col mb-2', { [styles.tallNameCol]: compare })}>{name}</div>

        <div className="col-1 mb-2">
          <div className="float-right mr-2">
            <Favorite measure={measure} />
          </div>
        </div>
      </div>

      <div className="row">
        <div className={cn('col mb-4', { [styles.tallDescriptionCol]: compare })}>
          <div className={cn(styles.description, { [styles.tallName]: compare })}>
            {compare ? measure.short_measure_description : measure.long_measure_description}
          </div>
        </div>
      </div>

      {!compare && (
        <div className="row">
          <div className="col mb-2">
            <a
              href={measure.access_measure}
              className={cn(
                'btn btn-primary btn-purple d-inline-block mb-5',
                styles.publisherButton
              )}
              onClick={onClickAccessMeasure}
            >
              Access Measure
            </a>
          </div>
        </div>
      )}
    </div>
  );
}

MeasureOverview.propTypes = {
  measure: measurePropType.isRequired,
  compare: PropTypes.bool
};

MeasureOverview.defaultProps = {
  compare: false
};

export default MeasureOverview;
