import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import cn from 'classnames';
import { ReactiveComponent } from '@appbaseio/reactivesearch';
import { Helmet } from 'react-helmet-async';

import styles from './PageCompareMeasures.module.css';

import SearchResultsLink from './SearchResultsLink';
import MeasureOverview from './MeasureOverview';
import MeasureBreakdown from './MeasureBreakdown';
import MeasureScores from './MeasureScores';

function PageCompareMeasures() {
  const [uuids, setUuids] = useState(null);

  useEffect(() => {
    const match = window.location.search.match(/\?uuids=(.*)/);
    setUuids(match ? decodeURIComponent(match[1]).split(',') : []);
  }, []);

  if (!uuids) return null;

  if (uuids.length < 2) {
    return <Redirect to="/measures" />;
  }

  return (
    <ReactiveComponent
      componentId="fetchMeasure"
      defaultQuery={() => ({
        query: {
          terms: { uuid: uuids }
        }
      })}
      render={(results) => {
        const measures = results.data;

        return (
          <>
            <Helmet>
              <title>Compare - IMPACT Measures Tool</title>
            </Helmet>

            <div className={styles.PageCompareMeasures}>
              <div className="clearfix">
                <a className="float-right" href="/favorites">My Favorites</a>
              </div>

              <SearchResultsLink />

              <div className="row">
                <div className="col mb-2">
                  <h1>Compare Measures</h1>
                </div>
              </div>

              <div className="row">
                {measures.map((measure) => {
                  return (
                    <div key={measure.uuid} className={cn('col-lg p-4 mx-3', styles.measureCol)}>
                      <MeasureOverview measure={measure} compare />
                      <MeasureScores measure={measure} compare />
                      <MeasureBreakdown measure={measure} compare />
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        );
      }}
    />
  );
}

export default PageCompareMeasures;
