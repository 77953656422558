import React, { useEffect } from 'react';
import { MultiList, RangeInput } from '@appbaseio/reactivesearch';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useStoreActions, useStoreState } from 'easy-peasy';

import styles from './SearchFilters.module.css';

import { TIME_TO_COMPLETE_MAX, CHILD_AGE_MAX, isDefault, dig } from '../shared/helpers';

import { removeUrlParam } from '../shared/urlParams';
import SearchFilter from './SearchFilter';

function SearchFilters(_props) {
  const { searchState, adultOnly, clearAllDisabled } = useStoreState((s) => s);
  const { resetState, updateSearchState } = useStoreActions((s) => s);

  useEffect(() => {
    // We can't easily alter data inside some react components, so we do this hack
    // to enforce the boundaries when possible
    document.querySelectorAll('#ageRangeCollapse input').forEach((input) => {
      enforceBoundaries(input, 0, CHILD_AGE_MAX);
    });
    document.querySelectorAll('#timeToCompleteCollapse input').forEach((input) => {
      enforceBoundaries(input, 0, TIME_TO_COMPLETE_MAX);
    });

    document.querySelectorAll('.rheostat button').forEach((input) => {
      input.removeAttribute('aria-label');
    });
  }, []);

  const clearAll = () => {
    resetState();
    removeUrlParam('measureList');
  };

  // const renderEnum = (values) => (label, count) =>
  //   (
  //     <div className="w-100">
  //       <span className={cn('d-inline-block', styles.itemLabel)}>{values[label - 1]}</span>
  //       <span className={cn('d-inline-block float-right', styles.count)}>{count}</span>
  //     </div>
  //   );

  const updateMultiList = (name) => (value) => {
    if (value === null) return;
    updateSearchState({ [name]: { value: value } });
  };

  const updateRange = (name) => (value) => {
    if (value === null) return;
    updateSearchState({ [name]: { value: [value.start, value.end] } });
  };

  return (
    <div className={cn('col-lg-3', styles.SearchFilters)}>
      <h2 className={styles.refineSearch}>Refine your search</h2>

      <button
        type="button"
        className={styles.clearAll}
        onClick={clearAll}
        disabled={clearAllDisabled}
      >
        <FontAwesomeIcon icon={faTimes} /> Clear All
      </button>

      <SearchFilter id="topic" title="Topic">
        <MultiList
          componentId="topic"
          dataField="topic"
          showSearch={false}
          value={dig(searchState, 'topic') || []}
          onChange={updateMultiList('topic')}
        />
      </SearchFilter>

      <SearchFilter
        id="monetaryCost"
        title="Cost"
        collapsed={isDefault(searchState, 'monetaryCost')}
      >
        <MultiList
          componentId="monetaryCost"
          dataField="monetary_cost"
          showSearch={false}
          value={dig(searchState, 'monetaryCost') || []}
          onChange={updateMultiList('monetaryCost')}
          renderItem={(label, count) => {
            const values = ['Free & accessible', 'Free & not accessible', 'At cost'];

            return (
              <div className="w-100">
                <span className={cn('d-inline-block', styles.itemLabel)}>{values[label - 1]}</span>
                <span className={cn('d-inline-block float-right', styles.count)}>{count}</span>
              </div>
            );
          }}
        />
      </SearchFilter>

      <SearchFilter
        id="ageRange"
        title="Age Range"
        collapsed={isDefault(searchState, 'ageRange') && isDefault(searchState, 'adult')}
      >
        <MultiList
          componentId="adult"
          dataField="adult"
          showSearch={false}
          value={dig(searchState, 'adult') || []}
          onChange={updateMultiList('adult')}
          renderItem={() => {
            return (
              <div className="w-100">
                <span className={cn('d-inline-block', styles.itemLabel)}>Adult only</span>
              </div>
            );
          }}
        />

        <div className={cn({ [styles.disabled]: adultOnly })}>
          <RangeInput
            componentId="ageRange"
            dataField="child_age"
            showHistogram={false}
            range={{
              start: 0,
              end: CHILD_AGE_MAX
            }}
            rangeLabels={{
              start: '0',
              end: '18+ years'
            }}
            value={dig(searchState, 'ageRange', {
              range: true,
              max: CHILD_AGE_MAX
            })}
            onChange={updateRange('ageRange')}
          />
        </div>
      </SearchFilter>

      <SearchFilter
        id="respondent"
        title="Respondent"
        collapsed={isDefault(searchState, 'respondent')}
      >
        <MultiList
          componentId="respondent"
          dataField="respondent"
          showSearch={false}
          value={dig(searchState, 'respondent') || []}
          onChange={updateMultiList('respondent')}
          // renderItem={renderEnum(RESPONDENT)}
        />
      </SearchFilter>

      <SearchFilter
        id="administrator"
        title="Administrator"
        collapsed={isDefault(searchState, 'administrator')}
      >
        <MultiList
          componentId="administrator"
          dataField="administrator"
          showSearch={false}
          value={dig(searchState, 'administrator') || []}
          onChange={updateMultiList('administrator')}
          // renderItem={renderEnum(ADMINISTRATOR)}
        />
      </SearchFilter>

      <SearchFilter
        id="measureType"
        title="Measure Type"
        collapsed={isDefault(searchState, 'measureType')}
      >
        <MultiList
          componentId="measureType"
          dataField="measure_type"
          showSearch={false}
          value={dig(searchState, 'measureType') || []}
          onChange={updateMultiList('measureType')}
          // renderItem={renderEnum(MEASURE_TYPE)}
        />
      </SearchFilter>

      <SearchFilter
        id="languages"
        title="Languages"
        collapsed={isDefault(searchState, 'languages')}
      >
        <MultiList
          componentId="languages"
          dataField="languages"
          showSearch={false}
          value={dig(searchState, 'languages') || []}
          onChange={updateMultiList('languages')}
        />
      </SearchFilter>

      <SearchFilter
        id="timeToComplete"
        title="Time to Complete"
        collapsed={isDefault(searchState, 'timeToComplete')}
        popover="Does not include additional coding or scoring time."
      >
        <RangeInput
          componentId="timeToComplete"
          dataField="time_to_complete"
          showHistogram={false}
          range={{
            start: 0,
            end: TIME_TO_COMPLETE_MAX
          }}
          stepValue={15}
          rangeLabels={{
            start: '0',
            end: '120+ minutes'
          }}
          value={dig(searchState, 'timeToComplete', {
            range: true,
            max: TIME_TO_COMPLETE_MAX
          })}
          onChange={updateRange('timeToComplete')}
        />
      </SearchFilter>
    </div>
  );
}

function enforceBoundaries(input, min, max) {
  input.setAttribute('min', 0);
  input.setAttribute('max', max);

  input.addEventListener('input', () => {
    if (input.value < min) {
      input.value = min;
    } else if (input.value > max) {
      input.value = max;
    }
  });
}

SearchFilters.propTypes = {};

export default SearchFilters;
