import PropTypes from 'prop-types';

export default PropTypes.shape({
  uuid: PropTypes.string,
  name: PropTypes.string,
  program_name: PropTypes.string,
  program_link: PropTypes.string,
  measure_family_name: PropTypes.string,
  topic: PropTypes.array,
  did_you_know: PropTypes.string,
  usability: PropTypes.number,
  cost: PropTypes.number,
  technical_merit: PropTypes.number,
  meets_minimum_cutoff: PropTypes.string,
  cultural_sensitivity: PropTypes.number,
  measure_type: PropTypes.array,
  electronic: PropTypes.bool,
  time_to_complete: PropTypes.shape({ gte: PropTypes.number, lte: PropTypes.number }),
  respondent: PropTypes.array,
  administrator: PropTypes.array,

  // this is a number because ReactiveSearch is weird about using a boolean field with MultiList
  adult: PropTypes.number,

  child_age: PropTypes.shape({ gte: PropTypes.number, lte: PropTypes.number }),
  monetary_cost: PropTypes.number,
  year_of_publication: PropTypes.number,
  short_measure_description: PropTypes.string,
  long_measure_description: PropTypes.string,
  languages: PropTypes.array,
  reference: PropTypes.string,
  access_measure: PropTypes.string
});
