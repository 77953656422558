import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { ReactiveComponent } from '@appbaseio/reactivesearch';

import styles from './RelatedMeasures.module.css';

import Diamond from './Diamond';
import { getCriteria } from '../shared/helpers';

function RelatedMeasures(props) {
  const { family } = props;

  return (
    <ReactiveComponent
      componentId="fetchRelatedMeasures"
      defaultQuery={() => ({
        query: {
          term: { measure_family_name: family }
        }
      })}
      render={(results) => {
        const measures = results.data.filter((m) => {
          return m.uuid !== props.currentUuid;
        });

        if (measures.length === 0) {
          return null;
        }

        return (
          <div className="row mb-4">
            <div className="col">
              <h3 className={cn('mb-2', styles.heading)}>Related Measures ({measures.length})</h3>
              {measures.map((measure) => {
                return (
                  <div className="row" key={measure.uuid}>
                    <div className="col-8 mt-4 mr-4">
                      <Link to={`/measure/${measure.uuid}`} className={styles.relatedLink}>
                        <h4 className={cn('my-2', styles.relatedName)}>{measure.name}</h4>
                      </Link>

                      <p>{measure.short_measure_description}</p>
                    </div>

                    <div className={cn('col-4', styles.diamondCol)}>
                      <Diamond
                        canvasId={`diamond-${measure.uuid}`}
                        measure={measure}
                        criteria={getCriteria()}
                        size="small"
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      }}
    />
  );
}

RelatedMeasures.propTypes = {
  family: PropTypes.string.isRequired,
  currentUuid: PropTypes.string
};

RelatedMeasures.defaultProps = {
  currentUuid: null
};

export default RelatedMeasures;
