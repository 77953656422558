import parse from 'html-react-parser';

const { REACT_APP_BASE_URL } = process.env;

const FOOTER_URL = new URL('/footer', REACT_APP_BASE_URL);

const HEADER_URL = new URL('/header', REACT_APP_BASE_URL);

const MANIFEST_URL = new URL(
  '/wp-content/themes/instituteforchildsuccess/public/manifest.json',
  REACT_APP_BASE_URL
);

// This function was generated by ChatGPT (including comments)
function resolveManifestUrl(relativeUrl) {
  // If the relativeUrl is already an absolute URL, return it as is
  if (/^(?:[a-z]+:)?\/\//i.test(relativeUrl)) {
    return relativeUrl;
  }

  // Parse the base URL into components
  const baseElements = MANIFEST_URL.toString().split('/');

  // If the base URL is not an absolute URL, return the relativeUrl as is
  if (baseElements.length < 3) {
    return relativeUrl;
  }

  // Remove last element of the base URL (e.g., the file or relative path)
  baseElements.pop();

  // If the relative URL starts with './', remove it
  if (relativeUrl.startsWith('./')) {
    relativeUrl = relativeUrl.substring(2);
  }

  // If the relative URL starts with '/', append it to the base URL host
  if (relativeUrl.startsWith('/')) {
    return `${baseElements.slice(0, 3).join('/')}${relativeUrl}`;
  }

  // If the relative URL starts with '../', go up one level in the base URL path
  while (relativeUrl.startsWith('../')) {
    relativeUrl = relativeUrl.substring(3);
    baseElements.pop();
  }

  // Add the relative URL to the base URL path
  baseElements.push(relativeUrl);

  // Join the elements and form the resolved URL
  const resolvedUrl = baseElements.join('/');
  return resolvedUrl;
}

function linkStylesheet(url) {
  const link = document.createElement('link');
  link.href = resolveManifestUrl(url);
  link.media = 'all';
  link.rel = 'stylesheet';
  link.type = 'text/css';
  document.querySelector('head').appendChild(link);
}

function linkMenuToggleScript(url) {
  const script = document.createElement('script');
  script.src = resolveManifestUrl(url);
  script.type = 'text/javascript';
  document.querySelector('head').appendChild(script);
}

async function fetchHeaderAndFooter() {
  const [footer, header, manifest] = await Promise.all(
    [FOOTER_URL, HEADER_URL, MANIFEST_URL].map(async url => {
      const response = await fetch(url);;
      return url.toString().endsWith('.json') ? response.json() : response.text();
    })
  );

  linkStylesheet(manifest['header.css']);
  linkMenuToggleScript(manifest['menutoggle.js']);

  return {
    footer: parse(footer),
    header: parse(header)
  };
}

export default fetchHeaderAndFooter;
