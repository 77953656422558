import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ReactiveBase, StateProvider } from '@appbaseio/reactivesearch';
import cn from 'classnames';

import styles from './App.module.css';

import PageFindMeasures from './PageFindMeasures';
import PageCompareMeasures from './PageCompareMeasures';
import PageShowMeasure from './PageShowMeasure';
import PageShowFamily from './PageShowFamily';
import PageFavorites from './PageFavorites';
import { storageSet, esHost, esCredentials, DEFAULT_CRITERIA } from '../shared/helpers';
import fetchHeaderAndFooter from '../shared/fetchHeaderAndFooter';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';

// need this to enable bootstrap popovers
const jQuery = require('jquery');

function App() {
  const [footer, setFooter] = useState(null);
  const [header, setHeader] = useState(null);

  useEffect(() => {
    if (!localStorage.getItem('criteria')) {
      storageSet('criteria', JSON.stringify(DEFAULT_CRITERIA));
    }

    if (!localStorage.getItem('favorites')) {
      storageSet('favorites', JSON.stringify([]));
    }

    const popovers = jQuery('[data-toggle="popover"]');
    if (popovers && typeof popovers.popover === 'function') {
      popovers.popover({ trigger: 'hover' });
    }

    (async() => {
      const { footer, header } = await fetchHeaderAndFooter();
      setFooter(footer);
      setHeader(header);
    })();
  }, []);

  return (
    <HelmetProvider>
      <Helmet
        onChangeClientState={(newState) => {
          if (newState.title && window.gtag) {
            window.gtag('config', 'UA-175877052-2', { page_title: document.title });
          }
        }}
      />

      <Router>
        <div className={cn('container-fluid', styles.App)}>
          <Helmet htmlAttributes={{ lang: 'en-us' }} />

          {header}

          <ReactiveBase
            app="measures"
            url={esHost()}
            credentials={esCredentials()}
            theme={{
              typography: {
                fontFamily: 'Lexend, sans-serif'
              }
            }}
          >
            <StateProvider
              onChange={(prevState, nextState) => {
                // don't clobber the state for the "find measures" page if we're on another page
                if (window.location.pathname !== '/measures') {
                  return;
                }

                // prevent reactive search from inserting a zero in the text field when the user
                // deletes the textbox contents in order to enter a new number
                if (
                  prevState.ageRange &&
                  prevState.timeToComplete &&
                  nextState.ageRange &&
                  nextState.timeToComplete
                ) {
                  const enforceOrder = (prev, next) => {
                    if (!prev.value || !next.value) return;

                    if (prev.value[1] !== next.value[1] && next.value[1] < next.value[0]) {
                      /* eslint-disable-next-line prefer-destructuring */
                      next.value[1] = prev.value[1];
                    }
                  };

                  enforceOrder(prevState.ageRange, nextState.ageRange);
                  enforceOrder(prevState.timeToComplete, nextState.timeToComplete);
                }
              }}
            />
            <main role="main" className={styles.main} id="site-content">
              <Switch>
                <Route exact path="/measures">
                  <PageFindMeasures />
                </Route>
                <Route path="/measures/compare">
                  <PageCompareMeasures />
                </Route>
                <Route path="/measure/:uuid">
                  <PageShowMeasure />
                </Route>
                <Route path="/family/:family">
                  <PageShowFamily />
                </Route>
                <Route path="/favorites">
                  <PageFavorites />
                </Route>
                <Route path="/">
                  <Redirect to="/measures" />
                </Route>
              </Switch>
            </main>
          </ReactiveBase>

          {footer}
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;
